import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import { User } from '@/models/User';
import axiosPublic from '@/utils/axiosPublic';
import { AxiosError } from 'axios';

interface AuthState {
  user: User | null | undefined;
  loading: boolean;
  error: string | null;
  login: (email: string, password: string) => Promise<void>;
  logout: () => Promise<void>;
  setUser: (user: User | null | undefined) => void;
  clearError: () => void;
}

export const useAuthStore = create<AuthState>()(
  devtools((set) => ({
    user: null,
    loading: false,
    error: null,
    setUser: (user) => set({ user, error: null }), // Clear error on successful login
    clearError: () => set({ error: null }), // Function to clear error
    login: async (email, password) => {
      set({ loading: true });
      try {
        const axiosInstance = axiosPublic();
        const response = await axiosInstance.post(
          '/api/auth/login',
          {
            email,
            password,
          },
          {
            headers: {
              'Content-Type': 'application/json',
            },
          }
        );

        set({ user: response.data, loading: false, error: null });
      } catch (error: unknown) {
        console.error('Error logging in:', error);
        let errorMessage = 'Login failed. Please try again.';
        if (error instanceof AxiosError) {
          errorMessage = error.response?.data?.error || errorMessage;
        }
        set({ loading: false, error: errorMessage });
      }
    },
    logout: async () => {
      set({ loading: true, error: null });
      try {
        const axiosInstance = axiosPublic();
        await axiosInstance.post('/api/auth/logout');
        set({ user: null, loading: false });
      } catch (error) {
        console.error(error);
        set({ loading: false });
      }
    },
  }))
);
