// src/utils/getApiUrl.ts

/**
 * Constructs the API URL based on the client and environment.
 *
 * @param client - The client identifier
 * @param baseUrl
 * @returns The constructed API URL as a string
 */

export const getApiUrl = (client: string, baseUrl: string | undefined) => {
  if (!baseUrl) {
    throw new Error('Base URL not provided');
  }

  return baseUrl.includes('localhost')
    ? `http://${baseUrl}`
    : `https://${client}.${baseUrl}`;
};
