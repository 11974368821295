import axios from 'axios';
import { getCookie } from 'cookies-next';
import { getApiUrl } from '@/utils/getApiUrl';
import { NextRequest } from 'next/server';

/**
 * Creates an Axios instance with the base URL set to the current domain.
 *
 * @returns An Axios instance configured with the base URL
 */

// Function to get subdomain/client/school from cookies
function getClientFromCookies(req?: NextRequest): string {
  const client = req ? getCookie('client', { req }) : getCookie('client');

  if (!client) {
    throw new Error('Client not found in cookies');
  }
  return client;
}

const axiosPublic = (req?: NextRequest) => {
  const baseUrl = process.env.NEXT_PUBLIC_APP_BASE_URL || '';
  const client = baseUrl.includes('localhost')
    ? 'testschool'
    : getClientFromCookies(req);

  const publicUrl = getApiUrl(client, baseUrl);

  return axios.create({
    baseURL: publicUrl, // Base URL for API requests
    headers: {
      'Content-Type': 'application/json',
    },
  });
};

export default axiosPublic;
